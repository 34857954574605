// Load jQuery from NPM
import $ from 'jquery';
import 'particles.js/particles';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
const { particlesJS } = window;

window.jQuery = $;
window.$ = $;

const isMobile = () =>  {
  // Check the user agent string
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = ['android', 'webos', 'iphone', 'ipad', 'ipod', 'blackberry', 'windows phone'];

  for (const keyword of mobileKeywords) {
    if (userAgent.indexOf(keyword) !== -1) {
      return true;
    }
  }

  // Check the screen width to detect small devices
  return window.innerWidth < 768; // You can adjust the threshold (768 in this case) to match your definition of mobile devices
}
console.log(isMobile())
if(!isMobile){
  particlesJS.load('particles', '/assets/particles.json');
}
else {
  particlesJS.load('particles', '/assets/particles-mobile.json');
}


$(document).ready(function() {
  $(window).scroll(function() {
    const height = $(window).scrollTop();
    if(height > 1) {
      $('.header').addClass('mooving');
      $('.scrollinfo').fadeOut()
    }
    else {
      $('.header').removeClass('mooving')
      $('.scrollinfo').fadeIn()
    }
  });

  $('.navbar-burger').on('click', (e) => {
    $(e.currentTarget).toggleClass('is-active')
    if($(e.currentTarget).hasClass('is-active'))
    {
      $('.navbar').show()

    }
    else {
      $('.navbar').hide()
    }
  })

});


const swiper = new Swiper(".mySwiper", {
  slidesPerView :2,
  autoplay: {
    delay: 1800,
    disableOnInteraction: false,
    pauseOnMouseEnter:true
  },
  speed : 800,
  loop: true,
  spaceBetween: 30,
  breakpoints: {
    700: {
      slidesPerView: 4,
      spaceBetween:10
    },
  }
});


AOS.init({
  delay: 140,
  offset:160,
  duration:500
});
